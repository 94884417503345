import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { Typography, Paper, Button, Chip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { vendorTypes } from '../../../utils/constants';
import AddVendor from './addVendors';
import { getVendors, deleteVendor, updateUserStatus } from './service';
import { getServiceTypes } from '../../../redux/actions/vendoraction';
import ViewVendorDetails from './viewVendor';
import ImportVendors from './importVendors';
import { ActionCell } from '../../../components/tableactions';
import { confirmBox } from '../../../utils/utils';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export default function VendorList() {
    const [openAddVendor, setOpenAddVendor] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [openExportPopup, setOpenExportPopup] = useState(false);
    const [rows, setRows] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [vendorData, setVendorData] = useState(null);

    const serviceTypes = useSelector(state => state.vendor.serviceTypes);
    const serviceTypesMap = serviceTypes?.reduce((acc, serviceType) => {
        acc[serviceType.id] = serviceType.name;
        return acc;
    }, {});

    const columns = [
        { field: 'slNo', headerName: 'Sl No', flex: 1 },
        { field: 'vendorLegalName', headerName: 'Legal Name', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 2 },
        {
            field: 'vendorType', headerName: 'Vendor Type', flex: 1,
            renderCell: ({ value }) => vendorTypes.find(e => e.id === value)?.name
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => {
                return row.user.status === 'active'
                    ? <Chip label='Active' color='success' onClick={() => updateStatus(row)} />
                    : <Chip label='InActive' color='warning' onClick={() => updateStatus(row)} />;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: ({ row }) => <ActionCell row={row} onAction={handleAction} actions={['View', 'Edit', 'Reset Password', 'Delete']} />
        }
    ];

    const resetPassword = async (data) => {
        if (await confirmBox(`You want reset password for ${data.firstName}`, 'Are you sure?')) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('password-reset') + '/' + data.userId);
            Swal.fire('Reseted!', 'A new password has been set and the user has been notified via email.', 'success');
        }
    };

    const handleAction = (action, row) => {
        if (action === 'View') {
            viewDetails(row);
        } else if (action === 'Edit') {
            editVendor(row);
        } else if (action === 'Reset Password') {
            resetPassword(row);
        } else if (action === 'Delete') {
            deleteUser(row);
        }
    };

    const viewDetails = (data) => {
        setVendorData(data);
        setOpenPreview(true);
    };

    const addNewVendor = () => {
        setVendorData(null);
        setOpenAddVendor(true);
        setIsEdit(false);
    };

    const editVendor = (data) => {
        setVendorData(data);
        setOpenAddVendor(true);
        setIsEdit(true);
    };

    const exportToCsv = () => {
        const csvContent = 'data:text/csv;charset=utf-8,' + Object.keys(rows[0]).join(',') + '\n' + rows.map(row => Object.values(row).join(',')).join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'vendors.csv');
        document.body.appendChild(link);
        link.click();
    };

    const deleteUser = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteVendor(data.id);
                const updatedRows = rows.filter(item => item.id !== data.id);
                setRows(updatedRows);
                Swal.fire('Deleted!', 'Doctor has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
            }
        });
    };

    const updateStatus = (data) => {
        const newStatus = data.user.status === 'active' ? 'inactive' : 'active';
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${newStatus === 'active' ? 'Activate?' : 'Deactivate?'}`,
            icon: newStatus === 'active' ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await updateUserStatus({ id: data.userId, status: newStatus });
                const updatedRows = rows.map(item => {
                    if (item.id === data.id) {
                        return { ...item, user: { ...item.user, status: newStatus } };
                    }
                    return item;
                });
                setRows(updatedRows);
                const message = newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!';
                Swal.fire('Status!', message, 'success');
            }
        });
    };

    const getVendorList = async () => {
        try {
            let results = await getVendors();
            const rows = results.map((item, index) => {
                return {
                    ...item,
                    slNo: index + 1,
                    name: [item.firstName, item.middleName, item.lastName].filter(e => e).join(' '),
                    email: item.user.email,
                    serviceTypeName: item.serviceTypeId ? serviceTypesMap[item.serviceTypeId] : '',
                };
            });
            setRows(rows);
        } catch (e) {
            setRows([]);
        }
    };

    useEffect(() => {
        if (serviceTypes?.length) {
            getVendorList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceTypes]);

    useEffect(() => {
        if (!serviceTypes) {
            getServiceTypes();
        }
    }, [serviceTypes]);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 2 }}>
                <div style={{ flex: 1 }}><GridToolbarQuickFilter /></div>
                {/* <Button size='small' variant='contained' color='secondary' onClick={() => setOpenExportPopup(true)}>Import</Button> */}
                {rows.length && <Button size='small' variant='contained' color='secondary' onClick={exportToCsv}>Export</Button>}
                <Button size='small' variant='contained' onClick={addNewVendor}>Add Vendor</Button>
            </GridToolbarContainer>
        );
    };

    return (
        <>
            {openAddVendor
                ? <AddVendor
                    isEdit={isEdit}
                    data={vendorData}
                    refresh={getVendorList}
                    closeAdding={() => setOpenAddVendor(false)}
                />
                : <div className='admin-area-2'>
                    <Typography variant='h5'>Vendors List</Typography>
                    <div className='page-content'>
                        <Paper>
                            <DataGrid
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                disableRowSelectionOnClick
                                columns={columns}
                                slots={{ toolbar: CustomToolbar }}
                                rows={rows}
                                pageSize={25}
                                className={clsx('empty-datagrid', { 'datagrid-loaded': rows.length !== 0 })}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        showExport: false,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Paper>
                    </div>
                </div>
            }

            <ViewVendorDetails
                data={vendorData}
                open={openPreview}
                closePopup={() => setOpenPreview(false)}
            />

            <ImportVendors
                open={openExportPopup}
                closePopup={() => setOpenExportPopup(false)}
                refresh={getVendorList}
            />
        </>
    );
}
