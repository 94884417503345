import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { Typography, IconButton, Paper, Button, Chip, Grid } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import httpClient from '../../../services/http';
import { getDepartmentList, getQualifications, getServiceTypes } from '../../../redux/actions/vendoraction';
import { getDoctors } from './service';
import ImportDoctors from './importDoctors';
import { APIUtils } from '../../../utils/apiutils';
import { confirmBox } from '../../../utils/utils';
import { ActionCell } from '../../../components/tableactions';

export default function DoctorList() {
    const navigate = useNavigate();
    const [openPreview, setOpenPreview] = useState(false);
    const [openExportPopup, setOpenExportPopup] = useState(false);
    const [rows, setRows] = useState([]);
    const [doctorData, serDoctorData] = useState(null);

    const departments = useSelector(state => state.vendor.departments);
    const qualifications = useSelector(state => state.vendor.qualifications);
    const serviceTypes = useSelector(state => state.vendor.serviceTypes);
    const departmentsMap = departments?.reduce((acc, department) => {
        acc[department.id] = department.name;
        return acc;
    }, {});
    const qualificationsMap = qualifications?.reduce((acc, qualification) => {
        acc[qualification.id] = qualification.name;
        return acc;
    }, {});
    const serviceTypesMap = serviceTypes?.reduce((acc, serviceType) => {
        acc[serviceType.id] = serviceType.name;
        return acc;
    }, {});

    useEffect(() => {
        if (!departments) {
            getDepartmentList();
        }
        if (!qualifications) {
            getQualifications();
        }
        if (!serviceTypes) {
            getServiceTypes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => setOpenPreview(false);

    const rowsWithSlNo = rows.map((row, index) => ({
        ...row,
        slNo: index + 1,
    }));

    const viewDoctorDetails = (data) => {
        serDoctorData(data);
        setOpenPreview(true);
    };

    const editDoctor = (data) => navigate('/admin/doctors/edit/' + data.userId);

    const deleteUser = async (data) => {
        if (await confirmBox('You want to delete', 'Are you sure?')) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id: data.user.id });
            const updatedRows = rows.filter(item => item.id !== data.id);
            setRows(updatedRows);
            Swal.fire('Deleted!', 'Doctor has been deleted.', 'success');
        }
    };

    const updateStatus = async (data) => {
        const newStatus = data.user.status === 'active' ? 'inactive' : 'active';
        const text = `You want to ${newStatus === 'active' ? 'Activate?' : 'Deactivate?'}`;
        const icon = newStatus === 'active' ? 'info' : 'warning';
        if (await confirmBox(text, 'Are you sure?', icon)) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('update-user-status'), { id: data.user.id, status: newStatus });
            const updatedRows = rows.map(item => {
                if (item.id === data.id) {
                    return { ...item, user: { ...item.user, status: newStatus } };
                }
                return item;
            });
            setRows(updatedRows);
            const message = newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!';
            Swal.fire('Status!', message, 'success');
        }
    };

    const resetPassword = async (data) => {
        if (await confirmBox(`You want reset password for ${data.firstName}`, 'Are you sure?')) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('password-reset') + '/' + data.userId);
            Swal.fire('Reseted!', 'A new password has been set and the user has been notified via email.', 'success');
        }
    };

    const handleAction = (action, row) => {
        if (action === 'View') {
            viewDoctorDetails(row);
        } else if (action === 'Edit') {
            editDoctor(row);
        } else if (action === 'Reset Password') {
            resetPassword(row);
        } else if (action === 'Delete') {
            deleteUser(row);
        }
    };

    const columns = [
        { field: 'slNo', headerName: 'Sl No', flex: 1, },
        { field: 'name', headerName: 'Name', flex: 2 },
        // { field: 'email', headerName: 'Email', flex: 2 },
        { field: 'experience', headerName: 'Experience', flex: 1 },
        { field: 'registrationNumber', headerName: 'Registration No.', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => {
                return row.user.status === 'active'
                    ? <Chip label='Active' color='success' onClick={() => updateStatus(row)} />
                    : <Chip label='InActive' color='warning' onClick={() => updateStatus(row)} />;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: ({ row }) => <ActionCell row={row} onAction={handleAction} actions={['View', 'Edit', 'Reset Password', 'Delete']} />
        }
    ];

    const getDoctorList = useCallback(async () => {
        try {
            if (departments?.length && qualifications?.length && serviceTypes?.length) {
                const results = await getDoctors();
                const processedRows = results.map(item => {
                    const { departmentId, qualificationId, serviceTypeId, firstName, middleName, lastName } = item;
                    const name = [firstName, middleName, lastName].filter(Boolean).join(' ');
                    const departmentName = departmentId ? departmentsMap[departmentId] : '';
                    const qualificationName = qualificationId ? qualificationsMap[qualificationId] : '';
                    const serviceTypeName = serviceTypeId ? serviceTypesMap[serviceTypeId] : '';
                    return { ...item, name, email: item.user.email, departmentName, qualificationName, serviceTypeName };
                });
                setRows(processedRows);
            }
        } catch (error) {
            console.error('Error fetching doctor list:', error);
            setRows([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments, qualifications, serviceTypes]);

    useEffect(() => {
        getDoctorList();
    }, [getDoctorList]);

    // const exportToCsv = () => {
    //     const fileName = 'doctors-' + new Date().getTime() + '.csv';
    //     const csvContent = 'data:text/csv;charset=utf-8,' + Object.keys(rows[0]).join(',') + '\n' + rows.map(row => Object.values(row).join(',')).join('\n');
    //     const encodedUri = encodeURI(csvContent);
    //     const link = document.createElement('a');
    //     link.setAttribute('href', encodedUri);
    //     link.setAttribute('download', fileName);
    //     document.body.appendChild(link);
    //     link.click();
    // };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 2 }}>
                <div style={{ flex: 1 }}>
                    <GridToolbarQuickFilter />
                </div>
                {/* <Button size='small' variant='contained' color='secondary' onClick={() => setOpenExportPopup(true)}>Import</Button> */}
                {/* {rows.length && <Button size='small' variant='contained' color='secondary' onClick={exportToCsv}>Export</Button>} */}
                <Link to='/admin/doctors/add'>
                    <Button size='small' variant='contained' >Add New Doctor</Button>
                </Link>
            </GridToolbarContainer>
        );
    };

    return (
        <>
            <div className='admin-area-2'>
                <Typography variant='h5'>Doctors List</Typography>
                <div className='page-content'>
                    <Paper>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            columns={columns}
                            slots={{ toolbar: CustomToolbar }}
                            rows={rowsWithSlNo}
                            pageSize={25}
                            className={clsx('empty-datagrid', {
                                'datagrid-loaded': rows.length !== 0
                            })}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    showExport: false,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Paper>
                </div>
            </div>


            {doctorData && <Dialog
                open={openPreview}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth='md'
                className='viewDetails'
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>Doctor Info</div>
                    <IconButton onClick={handleClose}><CloseOutlinedIcon /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Name</Grid>
                        <Grid item md={6} sm={12} xs={12}>{[doctorData?.firstName, doctorData?.lastName].filter(e => e).join(' ')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Profile Pic</Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            {doctorData?.profilePic && <img style={{ maxWidth: '100px' }} src={doctorData?.profilePic} alt='profile pic' />}
                            {!doctorData?.profilePic && <span>Not Available</span>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Legal Doc</Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            {doctorData?.legalDoc && <a href={doctorData?.legalDoc}>{doctorData?.legalDoc.split('/').pop()}</a>}
                            {!doctorData?.legalDoc && <span>Not Available</span>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Email ID</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.user?.email}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Mobile Number</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.mobileNumber}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Gender</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.gender}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Age</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.age}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Department</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.departmentName}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Registration No</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.registrationNumber}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Experience</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.experience} year(s)</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Qualification</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.qualificationName}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Service Type</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.serviceTypeName}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>About</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.about}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Award & Achievement</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.awards}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Address</Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            {[doctorData?.houseOrPlotNo, doctorData?.area, doctorData?.city, doctorData?.district, doctorData?.state, doctorData?.country].filter(e => e).join(', ')}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Service Name</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.serviceName}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Personal Visit Price</Grid>
                        <Grid item md={6} sm={12} xs={12}>Rs.{doctorData?.personalVisitPrice}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Tele Consultation Price</Grid>
                        <Grid item md={6} sm={12} xs={12}>Rs.{doctorData?.teleConsultationPrice}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Status</Grid>
                        <Grid item md={6} sm={12} xs={12}>{doctorData?.user?.status}</Grid>
                    </Grid>
                </DialogContent>
            </Dialog>}

            <ImportDoctors
                open={openExportPopup}
                closePopup={() => setOpenExportPopup(false)}
                refresh={getDoctorList}
            />
        </>
    );
}
