import React, { useState, useEffect } from 'react';
import './style.css';
import Swal from 'sweetalert2';
import { Typography, IconButton, Paper, Chip, Grid, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import clsx from 'clsx';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';
import { ActionCell } from '../../../components/tableactions';
import { confirmBox } from '../../../utils/utils';

export default function Customers() {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);

    const viewPatientDetails = (row) => {
        setSelectedRow(row);
        setOpenPreview(true);
    };

    const deleteUser = async (data) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id: data.user.id });
            setRows(rows.filter(item => item.id !== data.id));
            Swal.fire('Deleted!', 'Doctor has been deleted.', 'success');
        } else {
            Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
        }
    };

    const resetPassword = async (data) => {
        if (await confirmBox(`You want reset password for ${data.firstName}`, 'Are you sure?')) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('password-reset') + '/' + data.userId);
            Swal.fire('Reseted!', 'A new password has been set and the user has been notified via email.', 'success');
        }
    };

    const updateStatus = async (data) => {
        const newStatus = data.user.status === 'active' ? 'inactive' : 'active';
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${newStatus === 'active' ? 'Activate?' : 'Deactivate?'}`,
            icon: newStatus === 'active' ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        });

        if (result.isConfirmed) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('update-user-status'), { id: data.user.id, status: newStatus });
            setRows(rows.map(item => item.id === data.id ? { ...item, user: { ...item.user, status: newStatus } } : item));
            Swal.fire('Status!', newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!', 'success');
        }
    };

    const handleAction = (action, row) => {
        if (action === 'View') {
            viewPatientDetails(row);
        } else if (action === 'Reset Password') {
            resetPassword(row);
        } else if (action === 'Delete') {
            deleteUser(row);
        }
    };

    const rowsWithSlNo = rows.map((row, index) => ({
        ...row,
        slNo: index + 1,
    }));

    const columns = [
        { field: 'slNo', headerName: 'Sl No', flex: 1 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            valueGetter: ({ row }) => row.user.email
        },
        { field: 'mobileNumber', headerName: 'Phone No', flex: 1 },
        // { field: 'age', headerName: 'Age', flex: 1 },
        // { field: 'gender', headerName: 'Gender', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => (
                <Chip
                    label={row.user.status === 'active' ? 'Active' : 'InActive'}
                    onClick={() => updateStatus(row)}
                    color={row.user.status === 'active' ? 'success' : 'warning'}
                />
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: ({ row }) => <ActionCell row={row} onAction={handleAction} actions={['View', 'Reset Password', 'Delete']} />
        }
    ];

    const handleClose = () => setOpenPreview(false);

    const getPatientList = async () => {
        try {
            const res = await httpClient.get(APIUtils.getAdminAPIEndpoint('patient-list'));
            setRows(res.data.data);
        } catch (e) {
            console.error(e);
            setRows([]);
        }
    };

    useEffect(() => {
        getPatientList();
    }, []);

    const CustomToolbar = () => (
        <GridToolbarContainer sx={{ p: 2 }}>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );

    return (
        <>
            <div className='admin-area-2'>
                <Typography variant='h5'>Customers List</Typography>
                <div className='page-content'>
                    <Paper>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            columns={columns}
                            rows={rowsWithSlNo}
                            pageSize={25}
                            slots={{ toolbar: CustomToolbar }}
                            className={clsx('empty-datagrid', { 'datagrid-loaded': rows.length !== 0 })}
                        />
                    </Paper>
                </div>
            </div>

            {selectedRow && <Dialog
                open={openPreview}
                keepMounted
                onClose={handleClose}
                fullWidth
                maxWidth='sm'
                className='viewDetails'
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>Patient Info</div>
                    <IconButton onClick={handleClose}><CloseOutlined /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Name</Grid>
                        <Grid item md={6} sm={12} xs={12}>{[selectedRow?.firstName, selectedRow?.middleName, selectedRow?.lastName].filter(e => e).join(' ')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Email ID</Grid>
                        <Grid item md={6} sm={12} xs={12}>{selectedRow.user.email}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Mobile Numbe</Grid>
                        <Grid item md={6} sm={12} xs={12}>{selectedRow.mobileNumber}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Gender</Grid>
                        <Grid item md={6} sm={12} xs={12}>{selectedRow.gender}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Age</Grid>
                        <Grid item md={6} sm={12} xs={12}>{selectedRow.age}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>Status</Grid>
                        <Grid item md={6} sm={12} xs={12}>{selectedRow.user.status}</Grid>
                    </Grid>
                </DialogContent>
            </Dialog>}
        </>
    );
}
