import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

export function ActionCell({ row, onAction, actions }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action) => {
        onAction(action, row); // Send action and row data to parent
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                {actions.includes('View') &&
                    <MenuItem onClick={() => handleMenuItemClick('View')}>
                        <ListItemIcon>
                            <VisibilityIcon />
                        </ListItemIcon>
                        <ListItemText primary="View" />
                    </MenuItem>}
                {actions.includes('Edit') &&
                    <MenuItem onClick={() => handleMenuItemClick('Edit')}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </MenuItem>}
                {actions.includes('Reset Password') &&
                    <MenuItem onClick={() => handleMenuItemClick('Reset Password')}>
                        <ListItemIcon>
                            <LockResetIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reset Password" />
                    </MenuItem>}
                {actions.includes('Delete') &&
                    <MenuItem onClick={() => handleMenuItemClick('Delete')}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </MenuItem>}
            </Menu>
        </div>
    );
}

ActionCell.propTypes = {
    row: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
    actions: PropTypes.array.isRequired
};