import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { Grid, Typography, IconButton, Paper, Button, Chip } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddEditFieldExecutive from './addFieldExecutive';
import httpClient from '../../../services/http';
import { getFieldExecutives } from './service';
import { APIUtils } from '../../../utils/apiutils';
import { confirmBox } from '../../../utils/utils';
import { ActionCell } from '../../../components/tableactions';

export default function FeildEcecutive() {
    const [openAddFieldExecutive, setOpenAddFieldExecutive] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [rows, setRows] = useState([]);
    const [userData, setUserData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const columns = [
        { field: 'slNo', headerName: 'Sl No', flex: 0.3 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1.5 },
        { field: 'mobileNumber', headerName: 'Mobile No.', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => {
                return row.user.status === 'active'
                    ? <Chip onClick={() => updateStatus(row)} label='Active' color='success' />
                    : <Chip onClick={() => updateStatus(row)} label='Pending' color='warning' />;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: ({ row }) => <ActionCell row={row} onAction={handleAction} actions={['View', 'Edit', 'Reset Password', 'Delete']} />
        }
    ];

    const resetPassword = async (data) => {
        if (await confirmBox(`You want reset password for ${data.firstName}`, 'Are you sure?')) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('password-reset') + '/' + data.userId);
            Swal.fire('Reseted!', 'A new password has been set and the user has been notified via email.', 'success');
        }
    };

    const handleAction = (action, row) => {
        if (action === 'View') {
            viewDetails(row);
        } else if (action === 'Edit') {
            editFieldExecutive(row);
        } else if (action === 'Reset Password') {
            resetPassword(row);
        } else if (action === 'Delete') {
            deleteUser(row);
        }
    };


    const closeAddFieldExecutive = (refresh) => {
        setOpenAddFieldExecutive(false);
        if (refresh) {
            getFieldExecutiveList();
        }
    };

    const viewDetails = (data) => {
        setUserData(data);
        setOpenPreview(true);
    };

    const addNewFieldExecutive = () => {
        setUserData(null);
        setIsEdit(false);
        setOpenAddFieldExecutive(true);
    };

    const editFieldExecutive = (data) => {
        setUserData(data);
        setIsEdit(true);
        setOpenAddFieldExecutive(true);
    };

    const handleClose = () => setOpenPreview(false);

    const getFieldExecutiveList = useCallback(async () => {
        try {
            let results = await getFieldExecutives();
            const rows = results.map((item, index) => {
                const { firstName, middleName, lastName } = item;
                return {
                    ...item,
                    slNo: index + 1,
                    name: [firstName, middleName, lastName].filter(e => e).join(' '),
                    email: item.user.email,
                };
            });
            setRows(rows);
        } catch (e) {
            console.log(e);
            setRows([]);
        }
    }, []);

    useEffect(() => {
        getFieldExecutiveList();
    }, [getFieldExecutiveList]);

    const deleteUser = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id: data.user.id });
                const updatedRows = rows.filter(item => item.id !== data.id);
                setRows(updatedRows);
                Swal.fire('Deleted!', 'Doctor has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
            }
        });
    };

    const updateStatus = (data) => {
        const newStatus = data.user.status === 'active' ? 'inactive' : 'active';
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${newStatus === 'active' ? 'Activate?' : 'Deactivate?'}`,
            icon: newStatus === 'active' ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await httpClient.post(APIUtils.getAdminAPIEndpoint('update-user-status'), { id: data.user.id, status: newStatus });
                const updatedRows = rows.map(item => {
                    if (item.id === data.id) {
                        return { ...item, user: { ...item.user, status: newStatus } };
                    }
                    return item;
                });
                setRows(updatedRows);
                const message = newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!';
                Swal.fire('Status!', message, 'success');
            }
        });
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 2 }}>
                <div style={{ flex: 1 }}>
                    <GridToolbarQuickFilter />
                </div>
                <Button
                    size='small'
                    variant='contained'
                    onClick={addNewFieldExecutive}
                >ADD Field Executive</Button>
            </GridToolbarContainer>
        );
    };

    return (
        <>
            {openAddFieldExecutive
                ? <AddEditFieldExecutive
                    closeAdding={(refresh) => closeAddFieldExecutive(refresh)}
                    isEdit={isEdit}
                    data={userData}
                    refresh={getFieldExecutiveList}
                />
                : <div className='admin-area-2'>
                    <Typography variant='h5'>Field Executive List</Typography>
                    <div className='page-content'>
                        <Paper>
                            <DataGrid
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                disableRowSelectionOnClick
                                columns={columns}
                                slots={{ toolbar: CustomToolbar }}
                                rows={rows}
                                pageSize={25}
                                className={clsx('empty-datagrid', {
                                    'datagrid-loaded': rows.length !== 0
                                })}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        showExport: false,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Paper>
                    </div>
                </div>
            }

            <Dialog
                open={openPreview}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth='md'
                className='viewDetails'
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        Field Executive Info
                    </div>
                    <IconButton onClick={handleClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Name</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.name}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Certificate</Grid>
                        <Grid item md='6' sm='12' xs='12'>
                            {userData?.certificate && <a href={userData?.certificate}>{userData?.certificate.split('/').pop()}</a>}
                            {!userData?.certificate && <span>Not Available</span>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Email ID</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.user?.email}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Mobile Number</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.mobileNumber}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Aadhar Numer</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.aadharNumber}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>District</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.district}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>City</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.city}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Status</Grid>
                        <Grid item md='6' sm='12' xs='12'>{userData?.user.status}</Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
